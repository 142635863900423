import {
  Component,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';
// services
import { DataService } from './services/data/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  nativeElm: any;
  dataInput: any;
  dataInterpret: any;
  moverSize = 3;

  // private graphDataUrl = './assets/data/miserables.json';
  // private treeDataUrl = './assets/data/flare.json'

  childSizes: any = {
    interpreter: undefined, // 50 - this.moverToPercent(),
    input: undefined, // 50 - this.moverToPercent(),
    mover: this.moverSize
  };

  @ViewChild('mover') mover: ElementRef;

  constructor(
    private elm: ElementRef,
    private dataService: DataService
  ) {}

  get width() {
    return this.nativeElm.getBoundingClientRect().width;
  }

  get height() {
    return this.nativeElm.getBoundingClientRect().height;
  }

  ngOnInit() {
    this.nativeElm = this.elm.nativeElement;
    this.childSizes.interpreter = 50 - (this.moverToPercent() / 2);
    this.childSizes.input = 50 - (this.moverToPercent() / 2);

    // this.dataService.loadData(this.graphDataUrl);
    // console.log('generateData graph', this.dataService.generateData('graph'));
    // this.dataService.generateData('graph');
    // console.log('generate tree data', this.dataService.generateData('tree'));

    this.dataService.data.subscribe((data: any) => {
      console.log('data', data);
      this.dataInput = data;
    });
  }

  inputDataChanged(data: any) {
    // TODO: test this.dataService.data.next(data) // can avoid trouble
    // this.dataInterpret = data;
    setTimeout(() => {
      this.dataInterpret = data;
    }, 100);
  }

  private moverToPercent(): number {
    const result = 100 - (100 * (this.width - this.moverSize) / this.width); // (this.width / 100) * this.moverSize;
    return result;
  }

  onDragMover(e: any) {
    let percentage: number;
    if (this.mover.nativeElement.classList.contains('mover-portrait')) {
      // portrait
      percentage = (100 * (this.height - e.clientY)) / this.height;
    } else {
      // landscape
      percentage = (100 * (this.width - e.clientX)) / this.width;
    }
    // console.log('percentage x', percentageX);
    if (percentage && percentage <= 90 && percentage >= 10) {
      // console.log(percentageX);
      this.childSizes.interpreter = (100 - percentage) - (this.moverToPercent() / 2);
      this.childSizes.input = percentage - (this.moverToPercent() / 2);
    }
  }

  setGraphData() {
    // console.log('======== setGraphData');
    // this.dataService.loadData(this.graphDataUrl);
    this.dataService.generateData('graph');
  }

  setTreeData() {
    // console.log('======== setTreeData');
    // this.dataService.loadData(this.treeDataUrl);
    this.dataService.generateData('tree');
  }

  setLinearData() {
    // console.log('======== setLinearData');
    this.dataService.generateData('linear2d');
  }

  interpreterErrorHandler(error: any) {
    console.warn('--------interpreterErrorHandler--------', error);
  }
}
