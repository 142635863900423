import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// services
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  data: Subject<any> = new Subject();

  constructor(
    private http: HttpClient
  ) { }

  loadData(url: string): Observable<any> {
    const interSubject: Subject<any> = new Subject();
    this.http.get(url).subscribe((result: any) => {
      this.data.next(result);
      interSubject.next(result);
      interSubject.complete();
    });
    return interSubject;
  }

  isValidJSON(data: any): boolean {
    let result = false;
    console.log('sandbox data.service isValidJSON typeof data', typeof data);
    try {
      JSON.parse(data);
      result = true;
    } catch (error) {
      // do nothing
      console.error('Something wrong with data', error);
    }
    return result;
  }

  generateData(type: string) {
    // console.log('generateData type', type);
    let data = undefined;
    const random = () => this.randomFromTo(10, 80);

    switch (type) {
      case 'graph':
        data = this.generateGraph();
        break;
      case 'tree':
        data = this.generateTree();
        break;
      case 'linear2d':
        data = this.generateLinear2D();
        break;
    }

    this.data.next(data);
    /* setTimeout(() => {
      this.data.next(data);
    }, 1000); */
    
  }

  private generateGraph(): any {
    const random = () => this.randomFromTo(10, 80);
    const data = {};    
    const nodes = [];
    const links = [];
    // generate nodes
    const numberOfNodes = random();
    // console.log('numberOfNodes', numberOfNodes);
    for (let i = 0; i < numberOfNodes; i++) {
      nodes.push({
        id: this.randomString(this.randomFromTo(3, 15)),
        group: this.randomFromTo(1, 8)
      })
    }
    // set nodes
    data['nodes'] = nodes;
    // generate links
    const numberOfLinks = numberOfNodes + random();
    // console.log('numberOfLinks', numberOfLinks);
    for (let i = 0; i < numberOfLinks; i++) {
      const source = nodes[this.randomFromTo(0, nodes.length)].id;
      const target = nodes[this.randomFromTo(0, nodes.length)].id;
      links.push({
        source: source,
        target: target,
        value: this.randomFromTo(0, 20)
      });
    }
    // set links
    data['links'] = links;
    return data;
  }

  private generateTree(): any {
    const data = {};
    // data['name'] = this.randomString(12);
    let children = [];

    const pushChild = (children: boolean) => {
      let child = {};
      // push child with recursion children
      if (children) {
        const ch = new Array(this.randomFromTo(2, 10)).fill("").map(() => pushChild(this.randomBool(.1)));
        // console.log(ch);
        child = {
          name: this.randomString(this.randomFromTo(5, 15)),
          children: ch
        };
      // push final child
      } else {
        child = {
          name: this.randomString(this.randomFromTo(5, 15)),
          value: this.randomFromTo(400, 3000)
        };
      }
      return child;
    }

    for (let i = 0; i < this.randomFromTo(1, 50); i++) {
      children.push(pushChild(this.randomBool(.6)));
    }
    data['children'] = children;
    return data;
  } 

  private generateLinear2D(): any[] {
    const data = [];
    // console.log('generate linear2d data');
    const nodesCount = this.randomFromTo(20, 80);
    const xMax = this.randomFromTo(2, 100);
    const yMax = this.randomFromTo(2, 100);
    // const xName = this.randomString(this.randomFromTo(4, 9));
    // const yName = this.randomString(this.randomFromTo(4, 9));
    for (let i = 0; i < nodesCount; i++) {

      data.push({
        xName: this.randomFromTo(i, (xMax / nodesCount) * i),
        yName: this.randomFromTo(0, yMax)  
      });
    }
    // this.data.next(nodes);
    // console.log('nodes', JSON.stringify(nodes));
    return data;
  }





  private randomString(length: number): string {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  private randomFromTo(from: number, to: number): number {
    return Math.floor(Math.random() * to) + from;
  }

  private randomBool(probability: number): boolean {
    if (Math.random() < probability) {
      return true;
    }
    return false;
  }
}
